import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { IBrand } from "@/types/whitelabel";

@Component
export class AskForHelp extends Vue {
  @Getter("showHelpButtons") showHelpButtons!: boolean;
  @Getter("whiteLabel") currentBrand!: IBrand;

  get sendMessage() {
    const message = this.currentBrand.contactWhatsappMessage || "Olá, preciso de ajuda!";
    const number = this.currentBrand.contactWhatsappNumber
      ? `+55${this.currentBrand.contactWhatsappNumber.trim().replace(/[\s()]/g, "")}`
      : "+5511996636969";

    return `https://wa.me/${number}?text=${encodeURI(message)}`;
  }
}
